export default{
    'MESSAGE_NOPE': 'MESSAGE_NOPE',
    '_UI_STATE_CHANGED': '_UI_STATE_CHANGED',
    '_LOGIN_STATE_CHANGED': '_LOGIN_STATE_CHANGED',
    '_PRIMITIVE_LOADED': '_PRIMITIVE_LOADED',
    '_TOOL_CHANGED': '_TOOL_CHANGED',
    '_PROJECT_CHANGED': '_PROJECT_CHANGED',
    '_PROJECT_LOAD_FAIL': '_PROJECT_LOAD_FAIL',
    '_PROJECT_LOAD_COMPLETE': '_PROJECT_LOAD_COMPLETE',
    '_NEW_POINT': '_NEW_POINT',
    '_SELECTION_CHANGED': '_SELECTION_CHANGED',
    '_SELECTION_DID_UPDATE': '_SELECTION_DID_UPDATE',
    '_SELECTION_DID_ROTATE': '_SELECTION_DID_ROTATE',
    '_PROJECT_PROP_CHANGED': '_PROJECT_PROP_CHANGED',
    '_POINT_REMOVED':'_POINT_REMOVED',
    '_GET_SVG_LAYER':'_GET_SVG_LAYER',
    //
    'MESSAGE_DUMMY_EVENT': 'MESSAGE_DUMMY_EVENT'
};