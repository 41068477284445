import {abstractFilter} from './../abstractFilter';
import U from './../../lib-utils';
function F() {
    return (F.is(this) ? this.init : F.F).apply(this, Array.prototype.slice.call(arguments));
}

var P = F.leu_extend(abstractFilter).prototype;


P.apply_filter = function (current_value, params, field_name) {
    if (U.is_object(current_value) && (current_value instanceof Date)) {
        return current_value;
    }
    if ((typeof current_value) === 'string') {
        var rx = /^(\d{4})-(\d{1,2})-(\d{1,2})(?:(?:\s|T)(\d{1,2})(?::(\d{1,2})(?::(\d{1,2})){0,1}){0,1}){0,1}/i.exec(current_value);
        if (rx) {
            var Y = U.IntMoreOr(rx[1], 0, null);
            if (null !== Y) {
                var me = U.IntMoreOr(rx[2], -1, null);
                if (null !== me) {
                    var m = U.IntMoreOr(me - 1, -1, null);
                    if (null !== m) {
                        var d = U.IntMoreOr(rx[3], 0, null);
                        if (null !== d) {
                            var H = U.IntMoreOr(rx[4], 0, 0);
                            var i = U.IntMoreOr(rx[5], 0, 0);
                            var s = U.IntMoreOr(rx[6], 0, 0);
                            var rd = new Date();
                            rd.setFullYear(Y, m, d);
                            rd.setHours(H, i, s, 0);
                            return rd;
                        }
                    }
                }
            }
        }
        this.create_invalid_value(field_name, 'bad format (xml or SQL format required)');
    }
    return this.create_invalid_value(field_name, 'not a Date / XML/SQL Date[Time]');
};

export default F;


