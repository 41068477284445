import {abstractFilter} from './../abstractFilter';
import U from './../../lib-utils';
function F() {
    return (F.is(this) ? this.init : F.F).apply(this, Array.prototype.slice.call(arguments));
}

var P = F.leu_extend(abstractFilter).prototype;


P.apply_filter = function (current_value, params, field_name) {
    if (typeof (current_value) === 'string') {
        var m = /^[^@]{1,}@([^@]{1,}\.[^@.]{1,})$/.test(current_value);
        if (m) {
            if(params.has_param('domain')){
                var rd = U.NEString(params.get_param('domain').value,null);
                if(rd){
                    if(rd.toLowerCase()===m[1].toLowerCase()){
                        return current_value;
                    }else{
                        return this.create_invalid_value(field_name, 'email domain not match');
                    }
                }
            }
            return current_value;
        } else {
            return this.create_invalid_value(field_name, 'is not email');
        }
    }
    return this.create_invalid_value(field_name, 'is not string');
};

export default F;


