import {abstractFilter} from './../abstractFilter';
import filter from './../../lib-filter';
function F() {
    return (F.is(this) ? this.init : F.F).apply(this, Array.prototype.slice.call(arguments));
}

var P = F.leu_extend(abstractFilter).prototype;

// eslint-disable-next-line
P.apply_filter = function (current_value, params, field_name) {
    var fi = filter();
    var raw = fi.apply_filter_to_value(current_value,'NEString:trim=1;strip=1');
    var email = fi.apply_filter_to_value(current_value,'NEString:trim=1;strip=1,EmailMatch');
    var phone = fi.apply_filter_to_value(current_value,'NEString:trim=1;strip=1,PhoneMatch');
    if(!fi.is_value(email)){
        return email;
    }else if(!fi.is_value(phone)){
        return phone;
    }else if(!fi.is_value(raw)){
        return this.create_invalid_value(field_name, 'valid email address or phone number required');
    }else{
        return this.create_empty_value(field_name);
    }    
};

export default F;


