import U from './lib-utils';
import Filter from './lib-filter';


var filters = {
    status: 'String:strip=1;trim=1,NEString',
    server: 'String:strip=1;trim=1,NEString'
};

function frontend_params() {
    return (frontend_params.is(this) ? this.init : frontend_params.F).apply(this, Array.prototype.slice.call(arguments));
}

var P = U.fixup_constructor(frontend_params).prototype;

P.server = null;
P.status = null;
P.raw_data = null;

P.init = function (x) {
    x = U.safe_object(x);
    var filter = Filter();
    var clean = filter.apply_hash(x, filters);
    filter.throw_bad_value(clean);
    for (var k in clean) {
        if (Object.prototype.hasOwnProperty.call(clean, k) && !U.is_callable(clean[k])) {
            if (Object.prototype.hasOwnProperty.call(P, k) && this[k] === null) {
                this[k] = clean[k];
            }
        }
    }
    this.raw_data = x;
    return this;
};


P.has_value = function (n) {
    // перво наперво проверяем есть ли параметр с постфиксом -mobile
    if (U.is_mobile()) {
        if (Object.prototype.hasOwnProperty.call(this.raw_data, [n, '-mobile'].join(''))) {
            return true;
        }
    }
    // even if mobile - check main property if no -mobile property
    return (Object.prototype.hasOwnProperty.call(this.raw_data, n));
};
P.get_value = function (n, def) {
    if (U.is_mobile()) {
        if (Object.prototype.hasOwnProperty.call(this.raw_data, [n, '-mobile'].join(''))) {
            return this.raw_data[[n, '-mobile'].join('')];
        }
    }
    if (Object.prototype.hasOwnProperty.call(this.raw_data, n)) {
        return this.raw_data[n];
    }
    return def;
};

P.filter_value = function (n, filters, throw_bad, def) {
    throw_bad = U.any_bool(throw_bad, false);
    var fakeObj = {};
    var raw_value = fakeObj;
    if (U.is_mobile()) {
        if (Object.prototype.hasOwnProperty.call(this.raw_data, [n, '-mobile'].join(''))) {
            raw_value = this.raw_data[[n, '-mobile'].join('')];
        }
    }
    if (raw_value === fakeObj) {
        if (Object.prototype.hasOwnProperty.call(this.raw_data, n)) {
            raw_value = this.raw_data[n];
        }
    }

    if (raw_value !== fakeObj) {
        var filter = Filter();
        var value = filter.apply_filter(raw_value, filters);
        if (filter.isValue(value)) {
            if (throw_bad) {
                filter.throw_bad_value(value);
            }
            return def;
        }
        return value;
    }
    return def;
};


export {frontend_params};

