import {abstractFilter} from './../abstractFilter';
import U from './../../lib-utils';
function F() {
    return (F.is(this) ? this.init : F.F).apply(this, Array.prototype.slice.call(arguments));
}

var P = F.leu_extend(abstractFilter).prototype;


P.apply_filter = function (current_value, params, field_name) {
    var vv = U.isObject(current_value) && (current_value instanceof File) ? current_value : null;
    if (null !== vv) {
        if (params.has_param('rx')) {
            var rx = U.NEString(params.get_param('rx').value, null);
            if (rx) {
                var flags = params.has_param('flags') ? U.NEString(params.get_param('flags').value, '') : '';
                var reg = new RegExp(rx, flags);
                if (!reg.test(vv.name)) {
                    return this.create_invalid_value(field_name, 'not match regex');
                }
            }
        }
        return vv;

    }
    return this.create_invalid_value(field_name, 'is not File');
};

export default F;


