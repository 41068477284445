import {abstractFilter} from './../abstractFilter';
import U from './../../lib-utils';
function F() {
    return (F.is(this) ? this.init : F.F).apply(this, Array.prototype.slice.call(arguments));
}

var P = F.leu_extend(abstractFilter).prototype;


P.apply_filter = function (current_value, params, field_name) {
    var c = U.any_bool(current_value, null);
    if (c === null) {
        return this.create_invalid_value(field_name, 'not a bool');
    }
    return c;
};

export default F;


