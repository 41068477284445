import {abstractFilter} from './../abstractFilter';
import U from './../../lib-utils';
import format from './../../lib-format';
function F() {
    return (F.is(this) ? this.init : F.F).apply(this, Array.prototype.slice.call(arguments));
}

var P = F.leu_extend(abstractFilter).prototype;


P.apply_filter = function (current_value, params, field_name) {
    var vv = U.floatOr(current_value, null);
    if (null !== vv) {
        var dec = 2;
        var withLocale = false;
        var trim_decimals = params.has_param('trim_decimals') && U.any_bool(params.get_param('trim_decimals').value, false);
        if (params.has_param('decimals')) {
            dec = U.intMoreOr(params.get_param('decimals').value, 0, 2);
        }
        var split_decimals = false;
        if (params.has_param('split_decimals')) {
            split_decimals = U.any_bool(params.get_param('split_decimals').value, false);
        }
        if (params.has_param('locale')) {
            withLocale = U.any_bool(params.get_param('locale').value, false);
        }
        var result = format.format_float(vv, dec, split_decimals, (withLocale && !trim_decimals)); // если нужен трим, то локаль не надо форматировать
        if (trim_decimals) {
            return result.split('.')[0];
        }
        return result;
    }
    return this.create_invalid_value(field_name, 'is not float');
};

export default F;


