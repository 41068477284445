import U from './../lib-utils';
import {is_string} from './filterUtils';

/**
 * Класс параметра фильтра. 
 * Параметр - просто пара имя:значение
 * Фильтр сам приведет параметр к нужному типу
 * Порядок использования параметров определяется самим фильтром
 * Ненужные параметры будут проигнорированы
 * 
 * @param {String} name
 * @param {Any} value (Фильтр обрабатывает параметр и приводит его тип на свое усмотрение)
 * @returns {filterParam}
 */
// eslint-disable-next-line
function filterParam(name, value) {
    return (filterParam.is(this) ? this.init : filterParam.F).apply(this, Array.prototype.slice.call(arguments));
}
var FPP = U.fixup_constructor(filterParam).prototype;

FPP.name = null;
FPP.value = null;

FPP.init = function (name, value) {
    this.name = U.NEString(name, null);
    this.value = value;
    return this;
};


FPP.isValid = function () {
    return !!(this.name);
};
FPP.is_valid = FPP.isValid;


/**
 * Преобразует строку "p1=v1;p2=v2" В набор параметров фильтра
 * @param {string} xs
 * @returns {filterParam[]}
 */
filterParam.parse_params_string = function (xs) {
    var xa = U.NEString(xs, '').split(';');
    var result = [];
    for (var i = 0; i < xa.length; i++) {
        var xia = U.NEString(xa[i], '').split('=');
        if (xia.length === 2) {
            var r = filterParam(decodeURIComponent(xia[0]), decodeURIComponent(xia[1]));
            if (r && r.is_valid()) {
                result.push(r);
            }
        }
    }
    return result;
};

/**
 * Преобразует объект вида {name:x,value:y} в параметр фильтра, если у него есть соответствующие свойства (name, value).
 * (возвращаемое значение все равно массив, только с одним значением)
 * Если нет {x:y} - то преобразуются все собственные перечисляемые свойства объекта 
 * Оба варианта ({name:x,value:y} и {x:y}) дадут одинаковый сет параметров (параметр "x" со занчением "y" )
 * Однако вариант {x:y} позволяет одновременно задать несколько параметров
 * @param {object} xo
 * @returns {filterParam[]}
 */
filterParam.parse_params_object = function (xo) {
    var result = [];
    var so = U.safe_object(xo);
    if (filterParam.is(so) && so.is_valid()) {
        result.push(so);
        return result;
    }
    if (Object.prototype.hasOwnProperty.call(so, 'name') && Object.prototype.hasOwnProperty.call(so, 'value') && U.NEString(so.name, null)) {
        var paramObj = filterParam(so.name, so.value);
        if (paramObj && paramObj.is_valid()) {
            result.push(paramObj);
            return result;
        }
    }
    for (var k in so) {
        if (Object.prototype.hasOwnProperty.call(so, k)) {
            if (filterParam.is(so[k]) && so[k].is_valid()) {
                result.push(so[k]);
            } else {
                var param_name = U.NEString(k, null);
                if (param_name) {
                    var param = filterParam(param_name, xo[k]);
                    param && param.is_valid() ? result.push(param) : 0;
                }
            }
        }
    }
    return result;
};

/**
 * Преобразует массив в набор параметров по следующим правилам:
 * Если элемент массива - строка, то #parse_params_string<br>
 * Если элемент массива - объект, то #parse_params_object<br>
 * Если элемент массива - массив, то рекурсивно #parse_params_array
 * @param {array} xa
 * @returns {filterParam[]}
 */
filterParam.parse_params_array = function (xa) {
    var result = [];
    var sa = U.safe_array(xa);
    for (var i = 0; i < sa.length; i++) {
        if (filterParam.is(sa[i]) && sa[i].is_valid()) {
            result.push(sa[i]);
        } else if (is_string(sa[i])) {
            result = result.concat(filterParam.parse_params_string(sa[i]));
        } else if (U.is_object(sa[i])) {
            result = result.concat(filterParam.parse_params_object(sa[i]));
        } else if (U.is_array(sa[i])) {
            result = result.concat(filterParam.parse_params_array(sa[i]));
        }
    }
    return result;
};


/**
 * Преобразует XML элемент param (другие типы игнорируются) в параметр фильтра по следующим правилам:
 *   - Если у элемента есть атрибуты name и value - то они формируют параметр.<br>
 *   - Если У элемента нет атрибутов name и value, либо name пустой, то производится поиск дочерних элементов <name></name> и <value></value><br>
 *   во втором случае используется  первая <b>пара</b>  - тоесть name1,name2,name3,value1,value2 даст пару name3=value1, остальные будут проигнорированы.
 * @param {Element} el
 * @returns {filterParam|null}
 */
filterParam.parse_param_xml_element = function (el) {
    if ('param' === el.tagName.toLowerCase()) {
        var attr_name = U.NEString(el.getAttribute('name'), null);
        if (attr_name) {
            if (el.hasAttribute('value')) {
                return filterParam(attr_name, el.getAttribute('value'));
            }
        }
        var nn = null, vn = null;
        for (var i = 0; i < el.children.length; i++) {
            var tn = el.children.item(i);
            if ('name' === tn.tagName.toLowerCase()) {
                nn = tn;
            } else if (tn.tagName.toLowerCase() === 'value') {
                vn = tn;
            }
            if (vn && tn) {
                break;
            }
        }
        if (nn && vn) {
            var name = U.NEString(nn.textContent, null);
            if (name) {
                var value = vn.innerHTML;
                return filterParam(name, value);
            }
        }
    }
    return null;
};

/**
 *  Преобразует XML элемент filter (другие типы игнорируются) в набор параметров фильтра по следующим правилам:<br>
 *  -  если у элемента есть атрибут params  - этот атрибут обрабатывается как строка параметров p1=v2;p2=v2 (#parse_params_string)<br>
 *  -  если у элемента есть дочерние элементы типа param, то каждый из них добавляет параметр по следующим правилам:<br>
 *     - Если у элемента есть атрибуты name и value - то они формируют отдельный параметр.<br>
 *     - Если У элемента нет атрибутов name и value, либо name пустой, то производится поиск дочерних элементов <name></name> и <value></value><br>
 *       (см #parse_param_xml_element) 
 *        
 *  Наличие атрибута не отменяет поиск дочерних элементов, тоесть новые параметры дописываются в набор.<br>
 *  Т.к. параметры использубтся по имени, то более поздний параметр перезапишет предыдущий с таким же именем.    
 * @param {Element} el
 * @returns {filterParam}
 */
filterParam.parse_params_xml = function (el) {
    var result = [];
    if ('filter' === el.tagName.toLowerCase()) {
        var atr_str = U.NEString(el.getAttribute('params'), null);
        if (atr_str) {
            result = result.concat(filterParam.parse_params_string(atr_str));
        }
        for (var i = 0; i < el.children.length; i++) {
            if ('param' === el.children.item(i).tagName.toLowerCase()) {
                var item = filterParam.parse_param_xml_element(el.children.item(i));
                if (item && item.is_valid()) {
                    result.push(item);
                }
            }
        }
    }

    return result;
};

/**
 * создает набор параметров фильтра:
 *  - Из строки вида p=v;p2=v2  (см #parse_params_string)
 *  - Из объекта вида {name:n,value:v}
 *  - Из объекта вида {x:y,z:p} (см #parse_params_object)
 *  - Из массива всего вышеперечисленного (см #parse_params_array)
 *  - Из XML элемента Filter:
 *    - Атрибут params в форме p=v;p2=v2
 *    - Дочерние элементы вида <param name="" value="" /><br>
 *    - Дочерние элементы вида <param><name>name</name><value>value</value></param><br>
 * *  Т.к. параметры использубтся по имени, то более поздний параметр перезапишет предыдущий с таким же именем.    
 * @param {string|object|array|Element} xany p=v string or {p:v} object or array of {name:x,valye:y} objects or <filter> dom element
 * @returns {filterParam[]} 
 */
filterParam.parse_params = function (xany) {
    if (is_string(xany)) {
        return filterParam.parse_params_string(xany);
    } else if (window.Element && (xany instanceof Element)) { // повыше - он тоже object
        return filterParam.parse_params_xml(xany);
    } else if (U.isObject(xany)) {
        return filterParam.parse_params_object(xany);
    } else if (U.is_array(xany)) {
        return filterParam.parse_params_array(xany);
    }
    return [];
};




export {filterParam};