import U from './lib-utils';
import axios from 'axios';
import {callback} from './lib-callback-manager';
import {central_data} from './central-data';
import subst from './lib-subst';
var H = null;

function translator() {
    return translator.is(H) ? H : ((translator.is(this) ? this.init : translator.F).apply(this, Array.prototype.slice.call(arguments)));
}
var P = U.fixup_constructor(translator).prototype;

P.lang = null;
P.section = null;
P.terms = null;
P.terms_to_translate = null;
P._ready_callbacks = null;
P.UPTO = null;
P.default_language = null;
P.required_version = null;
P.update_interval = null;

P.init_params = function (fep) {
    //DefaultNumber
    //DefaultNumber
    this.default_language = fep.filter_value('language-default', "String:trim=1;strip=1,NEString,DefaultString:default=ru", false, 'ru');
    this.required_version = fep.filter_value('language-version', "Int:more=0,DefaultInt:default=0", false, 0);
    this.update_interval = fep.filter_value('language-update-interval', "Int:more=0,DefaultInt:default=86400000", false, 86400000);
};

P.init = function (section) {
    H = this;
    this._ready_callbacks = [];
    this.section = U.NEString(section, 'admin');
    if (!this.section) {
        throw new Error('lib-translator: section name required');
    }
    this.init_params(central_data().frontend_params);
    this.lang = U.NEString(localStorage.getItem(['translator-', this.section, '-selected-language'].join('')), null);
    if (!this.lang) {
        if (this.default_language) {
            this.lang = this.default_language;
            this.load_lang_local();
        } else {
            this.run_language_selector();
        }
    } else {
        this.load_lang_local();
    }
    window.translator_instance = this;
    return this;
};

P.load_lang_local = function () {
    document.documentElement.setAttribute('lang', this.lang);
    try {
        var terms_s = localStorage.getItem(['translator', this.section, this.lang, 'terms'].join('-'));
        if (terms_s) {
            var terms_o = JSON.parse(terms_s);
            if (U.is_object(terms_o)) {
                this.terms = terms_o;
            }
        }
    } catch (e) {
        this.terms = null;
    }
    if (!this.terms) {
        this.force_load();
    } else {
        this.silent_load();
        this.resolve();
    }
    return this;
};

P.resolve = function () {
    var c = U.safe_array(this._ready_callbacks);
    this._ready_callbacks = {
        push: function () {
            var args = Array.prototype.slice.call(arguments);
            for (var i = 0; i < args.length; i++) {
                if (callback.is(args[i])) {
                    try {
                        args[i].run();
                    } catch (e) {
                        window.setTimeout(function () {
                            throw e;
                        }, 0);
                    }
                }
            }
        }
    };
    this._ready_callbacks.push.apply(this._ready_callbacks, c);
    return this;
};

P.ready = function (co, ca) {
    this._ready_callbacks.push(callback(co, ca));
    return this;
};


P.silent_load = function () {
    var last_uptime = U.intMoreOr(localStorage.getItem(['translator', this.section, this.lang, 'uptime'].join('-')), 0, 0);
    var current_version = U.intMoreOr(localStorage.getItem(['translator', this.section, this.lang, 'version'].join('-')), 0, 0);
    var delta = (new Date()).getTime() - last_uptime;
    if (delta > this.update_interval || (this.required_version > current_version)) {
        window.setTimeout(this.load_language_data.bindTo(this), 2000);
    }
    return this;
};

P.force_load = function () {
    this.load_language_data().then(this.try_reload.bindTo(this));
    return this;
};

P.try_reload = function () {
    if (!this.terms) {
        this.terms = {};
    }
    if (this.terms) {
        this.resolve();
    }
};

P.add_term_to_translations = function (term) {
    var cterm = U.NEString(term, null);
    if (cterm) {
        if (!U.is_array(this.terms_to_translate)) {
            this.terms_to_translate = [];
        }
        this.terms_to_translate.push(term);
        if (this.UPTO) {
            window.clearTimeout(this.UPTO);
            this.UPTO = null;
        }
        this.UPTO = window.setInterval(this.send_terms.bindTo(this), 10000);
    }
    return this;
};

P.install = function (app) {
    app.config.globalProperties.TT = this.translate.bindTo(this);
    app.config.globalProperties.TTS = this.translate_subst.bindTo(this);

};
P.send_terms = function () {
    var c = U.safe_array(this.terms_to_translate);
    this.terms_to_translate = [];
    if (this.UPTO) {
        window.clearTimeout(this.UPTO);
        this.UPTO = null;
    }
    var send_terms_url = central_data().mk_lang_api_url({action: 'post'}, 'Util/Lang');
    if (send_terms_url) {
        if (c.length) {
            this._terms_in_transition = c;
            var secretKey = U.NEString(localStorage.getItem('lib-translator-post-secret-key'), null);
            if (secretKey) {
                axios.post(send_terms_url, {
                    language: this.lang,
                    section: this.section,
                    terms: c,
                    secretKey: secretKey
                })
                        .then(this.on_post_done.bindTo(this))
                        .catch(this.on_post_fail.bindTo(this));
            }
        }
    }
};
P.on_post_fail = function () {
    if (U.is_array(this._terms_in_transition)) {
        for (var i = 0; i < this._terms_in_transition.length; i++) {
            this.terms_to_translate.push(this._terms_in_transition[i]);
        }
    }
};
P.on_post_done = function (request) {
    if (request && U.isObject(request.data)) {
        if (request.data.status === 'ok') {
            return this;
        }
    }
    return this.on_post_fail();
};
P.translate = function (term) {
    if (!Object.prototype.hasOwnProperty.call(this.terms, term)) {
        this.terms[term] = term;
        this.add_term_to_translations(term);
    }

    return this.terms[term];
};
P.translate_subst = function (term, o) {
    if (!Object.prototype.hasOwnProperty.call(this.terms, term)) {
        this.terms[term] = term;
        this.add_term_to_translations(term);
    }
    return subst(this.terms[term], U.safeObject(o));
};
P.load_language_data = function () {
    var urlt = central_data().frontend_params.filter_value('translator-language-url', "String:trim=1;strip=1,NEString,DefaultNull");
    var url = urlt.replace(/%section%/g, this.section).replace(/%language%/g, this.lang);
    //return request('GET', host + url, null, null, false, true).done(this, this.on_lang_load_response);
    try {
        var ax = axios.get(url).then(this.on_lang_load_response.bindTo(this)).catch(function () {
            console.log(arguments);
        });
    } catch (ee) {
        console.log(ee);
    }
    return ax;
};
P.on_lang_load_response = function (req) {
    if (req && U.isObject(req.data)) {
        if (req.data.status === 'ok') {
            var newterms = U.safe_object(req.data['language-pack']);
            if (!this.terms) {
                this.terms = {};
            }
            for (var k in newterms) {
                if (Object.prototype.hasOwnProperty.call(newterms, k) && (typeof (newterms[k]) === 'string')) {
                    if (newterms[k] === '--empty--') {
                        this.terms[k] = '';
                    } else {
                        this.terms[k] = U.NEString(newterms[k], '').replace(/\|/g, "\n");
                    }
                }
            }
            localStorage.setItem(['translator', this.section, this.lang, 'terms'].join('-'), JSON.stringify(this.terms));
            localStorage.setItem(['translator', this.section, this.lang, 'uptime'].join('-'), (new Date()).getTime());
            var now_version = U.intMoreOr(central_data().frontend_params.filter_value('language-version', "Int:more=0,DefaultInt:default=0"), 0, 0);
            localStorage.setItem(['translator', this.section, this.lang, 'version'].join('-'), now_version);
        }
    }
};
P.append_handle = function () {
    var bds = document.getElementsByTagName('body');
    if (bds.length) {
        bds[0].appendChild(this.handle);
    }
};
P.on_language_selected = function (lang) {
    localStorage.setItem(['translator-', this.section, '-selected-language'].join(''), lang);
    this.lang = lang;
    this.dialog.unmount();
    this.dialog = null;
    this.handle.remove();
    this.load_lang_local();
};
P.on_component_load_fullfiled = function () {
//    require('@/assets/css/popups.scss');
//    global_preloader().hide();
//    var app = createApp(m.default);
//    if (!this.handle) {
//        this.handle = document.createElement('div');
//        this.handle.classList.add('popup-static-wrapper');
//        this.handle.classList.add('language-selector-popup-static-wrapper');
//        this.handle.style.display = 'none';
//    }
//    this.append_handle();
//    this.handle.style.display = 'block';
//    app.mount(this.handle).set_callback(this, this.on_language_selected);
//    this.dialog = app;
};
P.run_language_selector = function () {
    // import('./components/language-selector').then(this.on_component_load_fullfiled.bindTo(this));
};
export {translator};