import {abstractFilter} from './../abstractFilter';
import U from './../../lib-utils';
import libFormat from './../../lib-format';
function F() {
    return (F.is(this) ? this.init : F.F).apply(this, Array.prototype.slice.call(arguments));
}

var P = F.leu_extend(abstractFilter).prototype;


P.apply_filter = function (current_value, params, field_name) {

    if (U.is_object(current_value) && (current_value instanceof Date)) {
        var format = 'Y-m-dTH:i:s';
        if (params.has_param('format')) {
            var aFormat = U.NEString(params.get_param('format').value, null);
            if (aFormat) {
                format = aFormat;
            }
        }
        return libFormat.format_date_time(current_value,format);

    }
    return this.create_invalid_value(field_name, 'not a DateObject');
};

export default F;


