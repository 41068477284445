/* 
 * Click nbfs://nbhost/SystemFileSystem/Templates/Licenses/license-default.txt to change this license
 * Click nbfs://nbhost/SystemFileSystem/Templates/Other/javascript.js to edit this template
 */

export default{
    TAG_FLAG_FILTER: 0x0001, // тег используется для фильтрации
    TAG_FLAG_MULTI: 0x0002, // тег может иметь несколько значений
    TAG_FLAG_MUSTHAVE: 0x0004,// тег попадает в теги фильма по умодчанию
    TAG_FLAG_ON_LIST: 0x0008, // тег присутствует на элементе списка
    TAG_FLAG_ON_LIST_TITLE: 0x0010, // заголовок тега присутствует на элементе списка (Жанр: бла-бла. Иначе прсто "бля-бля") (произносится всегда с сметкой)
    TAG_FLAG_ON_MAIN_TOP: 0x0020, // Тег выводится в список тегов на странице фильма
    TAG_FLAG_ON_MAIN_REQUIRES_NEWLINE: 0x0040, // Тег начинается с новой строки (Но не образует новый блок)
    TAG_FLAG_ON_MAIN_TOP_TITLE: 0x0080,// при показе на странице фильма тег показывается с меткой (произносится всегда с меткой)
    TAG_FLAG_ON_MEDIA_PAGE_TOP_OWN_BLOCK: 0x0100, // тэг должен образовывать отдельный элемент label (медиавиев - список тегов)
    TAG_FLAG_ALLOW_VALUE_CREATION:0x200, // разрешает добавление значения на лету
    TAG_FLAG_DUMMY: 0x0000
}
