import U from './../lib-utils';
import {filterInfo} from './filterInfo';
import {is_string} from './filterUtils';
import {filterDescriptor} from './filterDescriptor';

/**
 * Описатель одного поля в дескрипторе.
 * содержит имя свойства и упорядоченный набор filterInfo, применяемых к этому свойству
 * in_name - входное имя (по которому его читают из источника)
 * out_name - выходное имя (под которым результат будет сохранен в выходном хеше)
 * filters  - набор фильтров применяемых к этому значению
 * in_name и out_name взаимозаменяемы - в смысле если какой-то из них не является непустой строкой, то полагается равным второму
 * @param {String|null} in_name 
 * @param {String|null} out_name 
 * @param {array|object|string|Element} filters - любой тип, который сможет переварить #filterInfo.parse_filter_info 
 * @returns {property}
 */
// eslint-disable-next-line
function property(in_name, out_name, filters) {
    return (property.is(this) ? this.init : property.F).apply(this, Array.prototype.slice.call(arguments));
}

var DP = U.fixup_constructor(property).prototype;

DP.filters = null;
DP.in_name = null;
DP.out_name = null;
DP.in_name_is_path = false;
DP.in_name_path = null;

DP.init = function (in_name, out_name, filters) {
    this.filters = [];
    this.in_name = U.NEString(in_name, null);
    this.out_name = U.NEString(out_name, null);
    if (!this.in_name) {
        this.in_name = this.out_name;
    }
    if (!this.out_name) {
        this.out_name = this.in_name;
    }
    if (filters) {
        this.filters = this.filters.concat(filterInfo.parse_filter_info(filters));
    }
    var tsp = this.in_name.split('.');
    this.in_name_is_path = !!(tsp.length > 1);
    if (this.in_name_is_path) {
        this.in_name_path = tsp;
    }
    return this;
};

/**
 * 
 * @param {filterInfo} fi
 * @returns {property}
 */
DP.add_filter = function (fi) {
    if (filterInfo.is(fi) && fi.is_valid()) {
        this.filters.push(fi);
    }
    return this;
};

DP.addFilter = DP.add_filter;

/**
 * 
 * @returns {Boolean}
 */
DP.is_valid = function () {
    return !!(this.in_name);
};

DP.isValid = DP.is_valid;



property.parse_string = function (in_str) {
    var result = [];
    var sa = U.NEString(in_str, '').split('&');
    var rx = /^<(\S{1,})>(.*)$/i;
    for (var i = 0; i < sa.length; i++) {
        var ss = U.NEString(sa[i], null);
        if (ss) {
            var m = rx.exec(ss);
            if (m) {
                var sna = m[1].split(':');
                if (sna.length) {
                    var input_name = U.NEString(sna[0], null);
                    var output_name = input_name;
                    if (sna.length > 1) {
                        output_name = U.NEString(sna[1], null);
                    }
                    var prop = property(input_name, output_name, m[2]);
                    if (prop && prop.is_valid()) {
                        result.push(prop);
                    }
                } else {
                    throw new Error(['lib-filter: can extract property names from `', m[1], '` (input spec is `', ss, '`)'].join(''));
                }
            }
        }
    }

    return result;
};


property.parse_object = function (xo) {
    var result = [];
    var so = U.safe_object(xo);
    if (property.is(so)) {
        return [so];
    } else if (U.isObject(so) && ( Object.prototype.hasOwnProperty.call(so,'in') || Object.prototype.hasOwnProperty.call(so,'out')) && Object.prototype.hasOwnProperty.call(so,'filters')) {// переданный объект {in:,out:,filters:}
        var propObj = property(so.in, so.out, so.filters);
        if (propObj.is_valid()) {
            return [propObj];
        } else {
            return [];
        }
    }
    for (var k in so) {
        if ( Object.prototype.hasOwnProperty.call(so,k)) {
            if (U.isObject(so[k]) && (property.is(so[k]))) {
                result.push(so[k]);
            } else if (U.isObject(so[k]) &&  Object.prototype.hasOwnProperty.call(so[k],'filters') || Object.prototype.hasOwnProperty.call(so[k],'out')) {// объект дескриптор
                var prop = property(U.NEString(k, null), U.NEString(so[k].out, null), so[k].filters);
                if (prop && prop.is_valid()) {
                    result.push(prop);
                }
            } else if (U.is_array(so[k])) {// массив с описателями фильтров
                var propStr = property(U.NEString(k, null), U.NEString(k, null), so[k]);
                if (propStr && propStr.is_valid()) {
                    result.push(propStr);
                }
            } else if (U.is_string(so[k])) {//строка с описателем фильтра
                var propLine = property(U.NEString(k, null), U.NEString(k, null), so[k]);
                if (propLine && propLine.is_valid()) {
                    result.push(propLine);
                }
            }

        }
    }

    return result;
};


property.parse_array = function (xa) {
    var result = [], sa = U.safe_array(xa);

    for (var i = 0; i < sa.length; i++) {
        if (U.isObject(sa[i])) {
            result = result.concat(property.parse_object(sa[i]));
        } else if (U.is_array(sa[i])) {
            result = result.concat(property.parse_array(sa[i]));
        } else if (U.is_string(sa[i])) {
            result = result.concat(property.parse_string(sa[i]));
        }
    }
    return result;
};


property.parse_xml_property = function (xml_element) {
    var result = [];
    if (window.Element && U.is_object(xml_element) && (xml_element instanceof Element) && 'property' === xml_element.tagName.toLowerCase()) {
        var in_name = U.NEString(xml_element.getAttribute('in'), null);
        var out_name = U.NEString(xml_element.getAttribute('out'), null);
        if (in_name || out_name) {
            var prop = property(in_name, out_name, xml_element);
            if (prop && prop.is_valid()) {
                result.push(prop);
            }
        } else {
            throw new Error('lib-filter:  `property` tag must contain `in` or `out` attributes');
        }
    } else {
        throw new Error('lib-filter: parse_xml_property requires property tag');
    }
    return result;
};

property.parse_xml_tag = function (xml_tag) {
    var result = [];
    if (window.Element && U.isObject(xml_tag) && (xml_tag instanceof Element)) {
        for (var i = 0; i < xml_tag.children.length; i++) {
            if ('property' === xml_tag.children.item(i).tagName.toLowerCase()) {
                result = result.concat(property.parse_xml_property(xml_tag.children.item(i)));
            }
        }
    }

    return result;
}

/**
 * Принимает на вход:
 *   - Объект {in_name:{out:'',filters:[]|"filter:..."}}
 *   - Объект {in_name:"Filter:,Filter:"}
 *   - Объект {in_name:[filters]}
 *   - массив строк '<in:out>filter:,filter&<in:out>filter' #parse_string
 *   - возможно и одну строку, но на выходе все равно массив (#parse_string)
 *   - массив объектов {in:,out:,filters:}
 *   - xml Element <property>
 *   - xml Element <%wrapper%><property/><property/><%wrapper%>
 * @param {string|object|Element} xany
 * @returns {property[]}
 */
property.parse_properties = function (xany) {
    var result = [];
    if (U.isObject(xany) && filterDescriptor.is(xany)) {
        return xany.props;
    }
    if (U.isObject(xany) && property.is(xany)) {
        return [xany];
    } else if (window.Element && U.isObject(xany) && (xany instanceof Element)) {
        if ('property' === xany.tagName.toLowerCase()) {
            result = result.concat(property.parse_xml_property(xany));
        } else {
            result = result.concat(property.parse_xml_tag(xany));
        }
    } else if (is_string(xany)) {
        result = result.concat(property.parse_string(xany));
    } else if (U.is_array(xany)) {
        result = result = property.parse_array(xany);
    } else if (U.is_object(xany)) {
        result = result.concat(property.parse_object(xany));
    }
    return result;
};


export {property};
