<template>  
    <div class="main-menu" v-if="show_menu" role="menu" :aria-label="TT('app-menu')" 
         :aria-describedby="'app-menu-aria-info'" 
         tabindex="-1"
         @key-down.space.stop.prevent="enableControls"
         >
        <div class="main-menu-content" role="none">            
            <div class="main-menu-logo-tx" role="none" aria-hidden="true" >
                <div class="main-menu-logo-tx-cnt" v-text="TT('mainmenu.textlogo')"></div>
            </div>
            <template v-if="isAdminUser">
                <router-link @keydown.space.stop.prevent="click('/user-list')" to="/user-list" v-text="TT('menuitem.users')" role="menuitem" :tabindex="controlsEnabled" :aria-label="TT('aria-menu-item-users')"/>
                <router-link @keydown.space.stop.prevent="click('/film-list')" to="/film-list" v-text="TT('menuitem.films')" role="menuitem" :tabindex="controlsEnabled" :aria-label="TT('aria-menu-item-films')"/>
                <router-link @keydown.space.stop.prevent="click('/collection-list')" to="/collection-list" v-text="TT('menuitem.collections')" role="menuitem" :tabindex="controlsEnabled" :aria-label="TT('aria-menu-item-collections')"/>
                <router-link @keydown.space.stop.prevent="click('/tag-list')" to="/tag-list" v-text="TT('menuitem.tags')" role="menuitem" :tabindex="controlsEnabled" :aria-label="TT('aria-menu-item-tags')"/>
                <router-link style="display:none" @keydown.space.stop.prevent="click('/logs')" to="/logs" v-text="TT('menuitem.logs')" role="menuitem" :tabindex="controlsEnabled" :aria-label="TT('aria-menu-item-logs')"/>
                <router-link @keydown.space.stop.prevent="click('/news-list')" to="/news-list" v-text="TT('menuitem.news')" role="menuitem" :tabindex="controlsEnabled" :aria-label="TT('aria-menu-item-news')"/>
                <router-link @keydown.space.stop.prevent="click('/push-list')" to="/push-list" v-text="TT('menuitem.push')" role="menuitem" :tabindex="controlsEnabled" :aria-label="TT('aria-menu-item-push')"/>
                <router-link style="display:none" @keydown.space.stop.prevent="click('/support')" to="/support" v-text="TT('menuitem.support')" role="menuitem" :tabindex="controlsEnabled" :aria-label="TT('aria-menu-item-support')"/>
                <router-link style="display:none" @keydown.space.stop.prevent="click('/options')" to="/options" v-text="TT('menuitem.options')" role="menuitem" :tabindex="controlsEnabled" :aria-label="TT('aria-menu-item-options')"/>
                <router-link @keydown.space.stop.prevent="click('/review-list')" to="/review-list" v-text="TT('menuitem.reviews')" role="menuitem" :tabindex="controlsEnabled" :aria-label="TT('aria-menu-item-reviews')"/>
                <router-link @keydown.space.stop.prevent="click('/voc-list')" to="/voc-list" v-text="TT('menuitem.voc')" role="menuitem" :tabindex="controlsEnabled" :aria-label="TT('aria-menu-item-voc')"/>
                <router-link @keydown.space.stop.prevent="click('/film-cli/add')" to="/film-cli/add" v-text="TT('menuitem.films-indexer')" role="menuitem" :tabindex="controlsEnabled" :aria-label="TT('aria-menu-item-films-indexer')"/>
            </template>
            <template v-if="!isAdminUser">
                <router-link @keydown.space.stop.prevent="click('/film-list')" to="/film-list" v-text="TT('menuitem.films-cli')" role="menuitem" :tabindex="controlsEnabled" :aria-label="TT('aria-menu-item-films-cli')"/>
                <router-link @keydown.space.stop.prevent="click('/film-cli/add')" to="/film-cli/add" v-text="TT('menuitem.film-add-cli')" role="menuitem" :tabindex="controlsEnabled" :aria-label="TT('aria-menu-item-films-cli-add')"/>
            </template>
        </div>
    </div>
    <router-view />
</template>

<style lang="scss">
    #app {
        background: white;
        color:black;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        .main-menu {
            box-sizing: border-box;
            height: 3em;
            max-height: 3em;
            min-height: 3em;
            background: whitesmoke;
            border-bottom: 1px solid silver;
            &:focus{
                outline-offset:-3px;
                outline:2px solid black;
            }
            .main-menu-content {
                box-sizing: border-box;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: nowrap;
                padding: 0 1em;

                .main-menu-logo-tx {
                    box-sizing: border-box;
                    height: 100%;
                    margin-right: 1em;
                    .main-menu-logo-tx-cnt {
                        box-sizing: border-box;
                        height: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        font-weight: bold;
                        font-size: 1.25em;

                    }
                }
                a {
                    box-sizing: border-box;
                    margin-right: 1em;
                    color: black;
                    height: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    &:focus{
                        outline-offset:-3px;
                        outline:2px solid black;
                    }
                }
            }
        }
    }

</style>
<script>
    import {central_data} from '@/lib/vendor/eve/central-data';
    import U from '@/lib/vendor/eve/lib-utils';
    import router from '@/router/index';
    import libInfo from '@/lib/vendor/eve/lib-aria-describedby-driver';
    export default{
        name: 'app-main',
        data: function () {
            return{
                show_menu: false,
                controlsEnabled: '0',//enbled
                isAdminUser: false
            };
        },
        methods: {
            enableControls: function () {
                this.$data.controlsEnabled = (this.$data.controlsEnabled === '0' ? '-1' : '0');
            },
            check_login: function () {
                if (U.IntMoreOr(central_data().jwtMonitor.get_payload('id'), 0, null)) {
                    this.$data.show_menu = true;
                    this.$data.isAdminUser = central_data().jwtMonitor.isAdmin();
                } else {
                    this.$data.show_menu = false;
                    this.$data.isAdminUser = false;
                }
            },
            click: function (url) {
                router.push(url);
            }
        },
        created: function () {
            central_data().jwtMonitor.on(this, this.check_login);
            if (!libInfo().is_installed('app-menu-aria-info')) {
                libInfo().add('app-menu-aria-info', this.TT('app-menu-aria-info'));
            }
        },
        mounted: function () {
            this.check_login();
        }

    }
</script>