import {abstractFilter} from './../abstractFilter';
import U from './../../lib-utils';
function F() {
    return (F.is(this) ? this.init : F.F).apply(this, Array.prototype.slice.call(arguments));
}

var P = F.leu_extend(abstractFilter).prototype;


function format_phone(m, params) {
    if (params.has_param('digits') && U.any_bool(params.get_param('digits').value, false)) {
        return [[m[1]].join(''), m[2], m[3], m[4], m[5]].join('');
    }
    return [['+', m[1]].join(''), ['(', m[2], ')'].join(''), m[3], m[4], m[5]].join(' ');
}

P.apply_filter = function (current_value, params, field_name) {
    if (typeof (current_value) === 'number') {
        current_value = current_value.toString();
    }
    if (typeof (current_value) === 'string') {
        var m = /^(\d{1,})(\d{3})(\d{3})(\d{2})(\d{2})$/i.exec(U.NEString(current_value, '').replace(/\D/ig, ''));
        if (m) {
            if (params.has_param('country')) {
                var rdac = [];
                var rds = U.NEString(params.get_param('country').value, null);
                if (rds) {
                    var rda = rds.split(',');
                    for (var i = 0; i < rda.length; i++) {
                        var t = U.intMoreOr(U.NEString(rda[i], null), 0, null);
                        if (t) {
                            rdac.push(t);
                        }
                    }
                }
                if (rdac && rdac.length) {
                    var pc = U.intMoreOr(m[1], 0, null);
                    if (rdac.indexOf(pc) >= 0) {
                        return format_phone(m, params);
                    } else {
                        return this.create_invalid_value(field_name, 'phone country not allowed');
                    }
                }
            }
            return format_phone(m, params);
        } else {
            return this.create_invalid_value(field_name, 'is not phone number');
        }
    }
    return this.create_invalid_value(field_name, 'is not string');
};

export default F;


