import U from './lib-utils';
var R_WEBP = null;
function webp(callback) {
    if (U.is_callable(callback)) {
        if (null === R_WEBP) {
            var t = new Image();
            t.onload = t.onerror = function () {
                R_WEBP = !!(t.height === 2);
                callback(R_WEBP);
            };
            t.src = 'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';
        } else {
            callback(R_WEBP);
        }
    }
}



export {webp as support_webp};

