import {abstractFilter} from './../abstractFilter';
import U from './../../lib-utils';
function F() {
    return (F.is(this) ? this.init : F.F).apply(this, Array.prototype.slice.call(arguments));
}

var P = F.leu_extend(abstractFilter).prototype;


P.apply_filter = function (current_value, params, field_name) {
    if (U.is_object(current_value)) {
        return current_value;
    }
    return this.create_invalid_value(field_name, 'is not Object');
};

export default F;


