import U from '@/lib/vendor/eve/lib-utils';
import Filter from '@/lib/vendor/eve/lib-filter';
import TagFlag from '@/TAG_FLAG';


function TagValue() {
    return (TagValue.is(this) ? this.init : TagValue.F).apply(this, Array.prototype.slice.call(arguments));
}

var T = U.fixup_constructor(TagValue).prototype;

T.id = null;
T.value = null;
T.tag_id = null;
T.valueKey = null;
T.tagKey = null;
T.key = null;

T.init = function (data) {
    var cData = Filter().apply_hash(data, {
        "id": "Int:more=0,DefaultNull",
        "value": "String:trim=1;strip=1,DefaultNull",
        "tag_id": "Int:more=0,DefaultNull"
    });//Объект {in_name:"Filter:,Filter:"}
    this.id = cData.id;
    this.value = cData.value;
    this.tag_id = cData.tag_id;
    if (this.isValid()) {
        this.valueKey = ['V', this.id].join('');
        this.tagKey = ['T', this.tag_id].join('');
        this.key = ['T', this.tag_id, 'V', this.id].join('');
    }
    return this;
};

T.isValid = function () {
    return !!(this.id && this.value && this.tag_id);
};


function Tag() {
    return (Tag.is(this) ? this.init : Tag.F).apply(this, Array.prototype.slice.call(arguments));
}

var P = U.fixup_constructor(Tag).prototype;


P.id = null;
P.name = null;
P.flags = null;
P.key = null;
P.values = null;

P.init = function (data) {
    var cData = Filter().apply_hash(data, {
        "id": "Int:more=0,DefaultNull",
        "name": "String:trim=1;strip=1,DefaultNull",
        "flags": "Int:more=0,DefaultInt:default=0",
        "values": "NEArray,DefaultNull"
    });//Объект {in_name:"Filter:,Filter:"}
    this.id = cData.id;
    this.name = cData.name;
    this.flags = cData.flags;
    if (this.isTagValid()) {
        this.values = [];
        for (var i = 0; i < cData.values.length; i++) {
            var value = TagValue(cData.values[i]);
            if (value.isValid()) {
                this.values.push(value);
            }
        }
        this.key = ['T', this.id].join('');
    }
    return this;
};

P.isSingleTag = function () {
    return !(this.flags & TagFlag.TAG_FLAG_MULTI);
    // TAG_FLAG_MULTI: 0x0002, // тег может иметь несколько значений
    //TAG_FLAG_MUSTHAVE
};

P.isMultiFlag = function () {
    return !!(this.flags & TagFlag.TAG_FLAG_MULTI);
};

P.isMustHave = function () {
    return !!(this.flags & TagFlag.TAG_FLAG_MUSTHAVE);
};

P.isTagValid = function () {
    return !!(this.id && this.name);
};

P.isValid = function () {
    return !!(this.isTagValid() && this.values.length);
};

P.isRequired = function () {
    return !!(this.flags & TagFlag.TAG_FLAG_MUSTHAVE);
};

P.isValueCreationSupported = function(){
    return !!(this.flags & TagFlag.TAG_FLAG_ALLOW_VALUE_CREATION);  
};

P.findValue = function (valueId) {
    var vid = U.IntMoreOr(valueId, 0, 0);
    if (vid) {
        for (var i = 0; i < this.values.length; i++) {
            if (this.values[i].id === vid) {
                return this.values[i];
            }
        }
    }
    return null;
};



function TagList() {
    return (TagList.is(this) ? this.init : TagList.F).apply(this, Array.prototype.slice.call(arguments));
}

var L = U.fixup_constructor(TagList).prototype;
L.items = null;
L.index = null;

L.init = function (data) {
    this.items = [];
    this.index = {};
    var sa = U.safeArray(data);
    for (var i = 0; i < sa.length; i++) {
        var so = U.safeObject(sa[i]);
        var tag = Tag.F(so);
        if (tag.isValid()) {
            this.items.push(tag);
            this.index[tag.key] = tag;
        }
    }
    return this;
};

L.getTagById = function (id) {
    var key = ['T', id].join('');
    if (Object.prototype.hasOwnProperty.call(this.index, key)) {
        if (Tag.is(this.index[key])) {
            return this.index[key];
        }
    }
    return null;
};



export {Tag, TagValue, TagList};

