import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import {central_data} from '@/lib/vendor/eve/central-data';
import U from '@/lib/vendor/eve/lib-utils';
require('@/assets/css/common.scss');
central_data().ready(function () {
    axios.interceptors.response.use(function (response) {
        if (U.isObject(response.data)) {
            if (response.data.status === 'auth') {
                if (!/^\/login/.test(router.currentRoute.value.path)) {
                    router.replace({path: '/login', query: {ret: router.currentRoute.value.fullPath}});
                    return null;
                } else {
                    response.data.status = 'error';
                }
            }
        }
        //  console.log('login-incept:',response);
        return response;
    });
    U.getBody().setAttribute('aria-label', central_data().translator.translate('app-main-title'));
    createApp(App).use(router).use(central_data().translator).mount('#app');
});

