import U from './lib-utils';
function subst(tpl, io) {
    io = U.safeObject(io);
    tpl = U.NEString(tpl, '');
    for (var k in io) {
        if (Object.prototype.hasOwnProperty.call(io, k)) {
            if (!U.isCallable(io[k]) && !U.isObject(io[k]) && !U.is_array(io[k])) {
                tpl = tpl.replace(['%', k, '%'].join(''), io[k]);
            }
        }
    }
    return tpl;
}


export default subst;