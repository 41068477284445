import U from './lib-utils';
var H = null;

function infoUtil() {
    return infoUtil.is(H) ? H : ((infoUtil.is(this) ? this.init : infoUtil.F).apply(this, Array.prototype.slice.call(arguments)));
}

var P = U.fixup_constructor(infoUtil).prototype;

P.node = null;
P.installed = null;
P.init = function () {
    H = this;
    this.node = document.createElement('div');
    this.node.style.display = 'none';
    var bs = document.getElementsByTagName('body');
    if (bs.length) {
        bs[0].appendChild(this.node);
    }
    this.installed = {};
    return this;
};

P.add_description = function (node_id, text) {
    node_id = U.NEString(node_id, null);
    if (node_id) {
        var div = document.createElement('div');
        div.innerHTML = text;
        div.setAttribute('id', node_id);
        this.node.appendChild(div);
        this.installed[node_id] = true;
    }


};
P.addDescription = P.add_description;
P.add = P.add_description;
P.set = function (node_id, text) {
    node_id = U.NEString(node_id, null);
    if (node_id) {
        if (this.is_installed(node_id)) {
            var n = document.getElementById(node_id);
            if (n) {
                n.innerHTML = text;
            }
        } else {
            this.add(node_id, text);
        }
    }
};

P.is_installed = function (node_id) {
    return U.any_bool(this.installed[node_id], false);
};


export default infoUtil;