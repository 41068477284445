import {abstractFilter} from './../abstractFilter';
function F() {
    return (F.is(this) ? this.init : F.F).apply(this, Array.prototype.slice.call(arguments));
}

var P = F.leu_extend(abstractFilter).prototype;

// eslint-disable-next-line
P.apply_filter = function (current_value, params, field_name) {
    if ((typeof current_value) === 'string') {
        if (/^#{0,1}[0-9a-f]{6}$/i.exec(current_value)) {
            return ['#', current_value.replace(/^#/g, '')].join('');
        }
        if (/^#{0,1}[0-9a-f]{8}$/i.exec(current_value)) {
            return ['#', current_value.replace(/^#/g, '')].join('');
        }
        if (params.has_param('default') && /^#{0,1}[0-9a-f]{6}$/i.exec(params.get_param('default').value)) {
            return ['#', params.get_param('default').value.replace(/^#/g, '')].join('');
        }
        if (params.has_param('default') && /^#{0,1}[0-9a-f]{8}$/i.exec(params.get_param('default').value)) {
            return ['#', params.get_param('default').value.replace(/^#/g, '')].join('');
        }
        if (params.has_param('default')) {
            return '#000000';
        }
        return this.create_invalid_value(field_name, 'is not html color');
    }
    return this.create_invalid_value(field_name, 'is not html color (not a string)');
};

export default F;  


