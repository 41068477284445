import {abstractFilter} from './../abstractFilter';
import U from './../../lib-utils';
function F() {
    return (F.is(this) ? this.init : F.F).apply(this, Array.prototype.slice.call(arguments));
}

var P = F.leu_extend(abstractFilter).prototype;


P.apply_filter = function (current_value, params, field_name) {
    var vv = U.intOr(current_value,null);
    if(null!==vv){
        if(params.has_param('min')){
            var min = U.intOr(params.get_param('min').value,null);
            if(null!==min){
                if(min>vv){
                    return this.create_invalid_value(field_name, 'too small');
                }
            }
        }
        if(params.has_param('max')){
            var max = U.intOr(params.get_param('max').value,null);
            if(null!==max){
                if(max<vv){
                    return this.create_invalid_value(field_name, 'too large');
                }
            }
        }
        if(params.has_param('more')){
            var more = U.intOr(params.get_param('more').value,null);
            if(null!==more){
                if(more>=vv){
                    return this.create_invalid_value(field_name, 'too small');
                }
            }
        }
        if(params.has_param('less')){
            var less = U.intOr(params.get_param('less').value,null);
            if(null!==less){
                if(less<=vv){
                    return this.create_invalid_value(field_name, 'too large');
                }
            }
        }
        if(params.has_param('toString')){
            var asstr = U.any_bool(params.get_param('toString').value,false);
            if(asstr){
                return vv.toString();
            }
        }        
        return vv;
        
    }    
    return this.create_invalid_value(field_name, 'is not integer');
};

export default F;


