import {abstractFilter} from './../abstractFilter';
import U from './../../lib-utils';
function F() {
    return (F.is(this) ? this.init : F.F).apply(this, Array.prototype.slice.call(arguments));
}

var P = F.leu_extend(abstractFilter).prototype;


P.apply_filter = function (current_value, params, field_name) {
    if ((typeof current_value) === 'string') {
        if (params.has_param('strip') && U.any_bool(params.get_param('strip').value, false)) {
            current_value = current_value.replace(/<\/?[^>]+>/gi, '');
        }
        if (params.has_param('trim') && U.any_bool(params.get_param('trim').value, false)) {
            current_value = U.trim(current_value);
        }
        if (params.has_param('min')) {
            var min = U.intMoreOr(params.get_param('min').value, 0, null);
            if (min) {
                if (current_value.length < min) {
                    return this.create_invalid_value(field_name, 'is too short');
                }
            }
        }
        if (params.has_param('max')) {
            var max = U.intMoreOr(params.get_param('max').value, 0, null);
            if (max) {
                if (current_value.length > max) {
                    return this.create_invalid_value(field_name, 'is too long');
                }
            }
        }
        if (params.has_param('lowercase') && U.any_bool(params.get_param('lowercase').value, false)) {
            current_value = current_value.toLowerCase();
        }
        if (params.has_param('uppercase') && U.any_bool(params.get_param('uppercase').value, false)) {
            current_value = current_value.toUpperCase();
        }
        return current_value;
    }
    return this.create_invalid_value(field_name, 'is not string');
};

export default F;


