import U from './lib-utils';
import {Value, InvalidValue, EmptyValue} from './Filter/Value';
import {filterDescriptor} from './Filter/filterDescriptor';
import {filterInfo} from './Filter/filterInfo';
var H = null;

function Filter() {
    return Filter.is(H) ? H : ((Filter.is(this) ? this.init : Filter.F).apply(this, Array.prototype.slice.call(arguments)));
}

var P = U.fixup_constructor(Filter).prototype;


P.init = function () {
    H = this;
    return this;
};


P.is_value = function (x) {
    return Value.is(x);
};

P.isValue = P.is_value;

P.is_invalid_value = function (x) {
    return InvalidValue.is(x);
};
P.isInvalidValue = P.is_invalid_value;
P.is_empty_value = function (x) {
    return EmptyValue.is(x);
};
P.isEmptyValue = P.is_empty_value;

/**
 * 
 * 
 * @param {Any} v value
 * @param {Any} f any object convertable to array of filterInfo (#filterInfo.parse_filter_info)
 * @returns {Any}
 */
P.apply_filter_to_value = function (v, f) {
    var filter_list = filterInfo.parse_filter_info(f);
    var current_value = v;
    for (var i = 0; i < filter_list.length; i++) {
        current_value = filter_list[i].apply_filter(v);
    }
    return current_value;

};
P.applyFilterToValue = P.apply_filter_to_value;
P.apply_filter = P.apply_filter_to_value;
P.applyFilter = P.apply_filter_to_value;

/**
 * 
 * @param {array} va
 * @param {any} f any type convertable to array of filterInfo (#filterInfo.parse_filter_info)
 * @param {Boolean} skip default true. skip invalid members or throw error
 * @returns {any}
 */
P.apply_filter_to_each_in = function (va, f, skip) {
    var sa = U.safe_array(va);
    var r = [];
    skip = U.any_bool(skip, true);
    var filter_list = filterInfo.parse_filter_info(f);
    for (var i = 0; i < sa.length; i++) {
        var current_value = sa[i];
        for (var j = 0; j < filter_list.length; j++) {
            current_value = filter_list[i].apply_filter(current_value);
            if (Value.is(current_value)) {
                if (!skip) {
                    this.throw_bad_value(current_value);
                }
            } else {
                r.push(current_value);
            }
        }
    }
    return r;
};


P.applyFilterToEachIn = P.apply_filter_to_each_in;

/**
 * 
 * @param {array} va input array (of object)
 * @param {string} xml
 * @param {string} path path inside xml to filter descriptor
 * @param {bool} skip
 * @returns {any|Array|.abstractDefaultFilter@call;leu_extend.prototype.apply_filter_to_each_in.r|P.apply_filter_to_each_in.r|.U@call;fixup_constructor.prototype.apply_filter_to_each_in.r|.F@call;leu_extend.prototype.apply_filter_to_each_in.r}
 */
P.apply_filter_to_each_hash_in_xml = function (va, xml, path, skip) {
    var filter_list = filterDescriptor.create_from_xml_string(xml, path);
    return this.apply_filter_to_each_hash_in(va, filter_list, skip);
};

P.create_descriptor_from_xml = function (xml, path) {
    return filterDescriptor.create_from_xml_string(xml, path);
};

/**
 * 
 * @param {Array} va input array (of hash)
 * @param {any} descriptor filterDescriptor compatible
 * @param {Bool} skip  skip (true) or throw (false) on invalid items
 * @returns {Array}
 */
P.apply_filter_to_each_hash_in = function (va, descriptor, skip) {
    var sa = U.safe_array(va);
    var r = [];
    skip = U.any_bool(skip, true);
    var filter_list = filterDescriptor(descriptor);
    for (var i = 0; i < sa.length; i++) {
        try {
            if (U.is_object(sa[i])) {
                var saif = this.apply_hash(sa[i], filter_list);
                this.throw_bad_value(saif);
                r.push(saif);
            }
        } catch (e) {
            if (skip) {
                continue;
            } else {
                throw e;
            }
        }
    }
    return r;
};

P.read_path = function (obj, path_a) {
    var cu = U.is_object(obj) ? obj : null;
    for (var i = 0; i < path_a.length - 1; i++) {
        if (!U.is_object(cu)) {
            throw new Error('path find error');
        }
        if (U.is_object(cu[path_a[i]])) {
            cu = cu[path_a[i]];
        } else {
            throw new Error('path find error');
        }
    }
    if (U.is_object(cu) && (path_a[path_a.length - 1] in cu)) {
        return cu[path_a[path_a.length - 1]];
    }

    throw new Error('path find error');
};

/**
 * 
 * @param {Object} vo
 * @param {any} desc any what can be cast to filterDescriptor, except json and XML representation
 * @returns {undefined}
 */
P.apply_hash = function (vo, desc) {
    var descriptor = filterDescriptor(desc);
    var so = U.safe_object(vo);
    var ro = {};
    for (var i = 0; i < descriptor.props.length; i++) {
        var prop = descriptor.props[i];
        var current_value = null;
        if (!prop.in_name_is_path && (prop.in_name in so)) {
            current_value = so[prop.in_name];
        } else if (prop.in_name_is_path) {
            try {
                current_value = this.read_path(so, prop.in_name_path);
            } catch (ee) {
                current_value = EmptyValue(prop.in_name);
            }
        } else {
            current_value = EmptyValue(prop.in_name);
        }
        for (var j = 0; j < prop.filters.length; j++) {
            current_value = prop.filters[j].apply_filter(current_value, prop.in_name);
        }
        ro[prop.out_name] = current_value;

    }
    return ro;
};
P.applyHash = P.apply_hash;

P.apply = P.apply_hash;

P.apply_hash_xml = function (v, xml, path) {
    return this.apply_hash(v, filterDescriptor.create_from_xml_string(xml, path));
};

P.apply_hash_json = function (v, json) {
    return this.apply_hash(v, JSON.parse(json));
};

P.throw_bad_value = function (x) {
    if (Value.is(x)) {
        throw new Error(x.get_message());
    } else if (U.is_object(x)) {
        for (var k in x) {
            if (Object.prototype.hasOwnProperty.call(x, k)) {
                if (Value.is(x[k])) {
                    throw new Error(x[k].get_message());
                }
            }
        }
    } else if (U.is_array(x)) {
        //if array of some values - search bad value in array. no variant [{x:badValue}]?
        for (var i = 0; i < x.length; i++) {
            if (Value.is(x[i])) {
                throw new Error(x[i].get_message);
            }
        }
    }
    return this;
};

export default Filter;