import U from './../lib-utils';
import {filterParam} from './filterParam';
import {abstractFilter} from './abstractFilter';
import {is_string, get_filter_instance} from './filterUtils';

/**
 * Описатель одного фильтра из дескриптора
 * Содержит собственно инстанс фильтра и набор параметров
 * @param {AbstractFilter|Any} filter интанс фильтра
 * @param {string|object|array|Element|null} params набор параметров как-ибо пригодный для обработки #filterParam.parse_params
 * @returns {filterInfo}
 */
// eslint-disable-next-line
function filterInfo(filter, params) {
    return (filterInfo.is(this) ? this.init : filterInfo.F).apply(this, Array.prototype.slice.call(arguments));
}
var FIP = U.fixup_constructor(filterInfo).prototype;

FIP.filter = null;
FIP.params = null;

FIP.init = function (filter, params) {
    this.set_filter(filter);
    this.params = {};
    if (params) {
        var parsed_params = filterParam.parse_params(params);
        for (var i = 0; i < parsed_params.length; i++) {
            this.add_param_object(parsed_params[i]);
        }
    }
    return this;
};

FIP.is_valid = function () {
    return abstractFilter.is(this.filter);
};
FIP.isValid = FIP.is_valid;

/**
 * 
 * @param {abstractFilter|any} filter
 * @returns {filterInfo}
 */
FIP.set_filter = function (filter) {
    this.filter = abstractFilter.is(filter) ? filter : null;
    return this;
};

FIP.setFilter = FIP.set_filter;

FIP.add_param = function (param_name, param_value) {
    var pni = U.NEString(param_name, null);
    if (pni) {
        this.add_param_object(filterParam(pni, param_value));
    }
    return this;
};

FIP.addParam = FIP.add_param;
FIP.add_parameter = FIP.add_param;
FIP.addParameter = FIP.add_param;


FIP.add_param_object = function (s) {
    if (filterParam.is(s) && s.is_valid()) {
        this.params[s.name] = s;
    }
    return this;
};
FIP.addParamObject = FIP.add_param_object;
FIP.add_parameter_object = FIP.add_param_object;
FIP.addParameterObject = FIP.add_param_object;

FIP.has_param = function (k) {
    var x = U.NEString(k, null);
    if (x) {
        return filterParam.is(this.params[x]);
    }
    return false;
};

FIP.hasParam = FIP.has_param;
FIP.hasParameter = FIP.has_param;
FIP.has_parameter = FIP.has_param;

FIP.get_param = function (k) {
    var x = U.NEString(k, null);
    if (x) {
        return filterParam.is(this.params[x]) ? this.params[x] : null;
    }
    return null;
};

FIP.get_filter = function () {
    return this.filter;
};

FIP.getFilter = FIP.get_filter;

FIP.apply_filter = function (x,fn) {
    return this.filter.apply(x, this,fn);
};

FIP.applyFilter = FIP.apply_filter;




filterInfo.parse_filter_info_string = function (xs) {
    var result = [];
    var va = U.NEString(xs, '').replace(/^<\S{0,}>/ig, '').split(',');
    for (var i = 0; i < va.length; i++) {
        var vas = U.NEString(va[i], null);
        if (vas) {
            var fpa = vas.split(':');
            if (fpa.length) {
                var filter_name = U.NEString(fpa[0], null);
                if (filter_name) {
                    var item = filterInfo(get_filter_instance(filter_name), fpa.slice(1).join(':'));
                    if (item && item.is_valid()) {
                        result.push(item);
                    }
                }
            }
        }
    }
    return result;
};

filterInfo.parse_filter_info_object - function (xo) {
    var result = [];
    var so = U.safe_object(xo);
    if (filterInfo.is(so)) {
        result.push(so);
        return result;
    }
    var filter_def = U.NEString(so.filter, null);
    if (filter_def) {
        var rr = filterInfo.parse_filter_info_string(filter_def);
        if (rr.length === 1) {
            if (Object.prototype.hasOwnProperty.call(so,'params')) {
                var additional_params = filterParam.parse_params(so.params);
                for (var i = 0; i < additional_params.length; i++) {
                    rr[0].add_param_object(additional_params[i]);
                }
            }
        } else if (rr.length > 1) {
            throw new Error('lib-filter: filterInfo in object representation must contain 1 filter exactly');
        }
    }
    return result;
};

filterInfo.parse_filter_info_array = function (xa) {
    var result = [];
    var sa = U.safe_array(xa);
    for (var i = 0; i < sa.length; i++) {
        if (U.is_array(sa[i])) {
            result = result.concat(filterInfo.parse_filter_info_array(sa[i]));
        } else if (is_string(sa[i])) {
            result = result.concat(filterInfo.parse_filter_info_string(sa[i]));
        } else if (U.is_object(sa[i])) {
            result = result.concat(filterInfo.parse_filter_info_object(sa[i]));
        }
    }
    return result;
};

filterInfo.parse_xml_property_element = function (xml_property) {
    var result = [];
    if (window.Element && U.is_object(xml_property) && (xml_property instanceof Element)) {
        if ('property' === xml_property.tagName.toLowerCase()) {
            var filters_attr = U.NEString(xml_property.getAttribute('filters'), null);
            if (filters_attr) {
                result = result.concat(filterInfo.parse_filter_info_string(filters_attr));
            } else {
                var filter_attr = U.NEString(xml_property.getAttribute('filter'), null);
                if (filter_attr) {
                    result = result.concat(filterInfo.parse_filter_info_string(filter_attr));
                }
            }
            for (var i = 0; i < xml_property.children.length; i++) {
                var fn = xml_property.children.item(i);
                if ('filter' === fn.tagName.toLowerCase()) {
                    result = result.concat(filterInfo.parse_xml_filter_element(fn));
                }
            }
        } else {
            throw new Error('lib-filter: parse_xml_property_element requires <property> tag');
        }
    }
    return result;
};

filterInfo.parse_xml_filter_element = function (xml_filter) {
    var result = [];
    if (window.Element && U.is_object(xml_filter) && (xml_filter instanceof Element)) {
        if ('filter' === xml_filter.tagName.toLowerCase()) {
            var filter_name = U.NEString(xml_filter.getAttribute('name'), U.NEString(xml_filter.getAttribute('class'), null));
            if (filter_name) {
                result.push(filterInfo(get_filter_instance(filter_name), xml_filter));
            }
        } else {
            throw new Error('lib-filter: parse_xml_filter_element requires <filter> tag');
        }
    }
    return result;
};

/**
 * Разбирает переданный параметр по следующим правилам:
 *  если <b>string === typeof xany</b>, то ожидается строка типа filter:p1=v1;p2=v2,filter2,filter3:px=vx..... #parse_filter_info_string
 *  если <b>object === typeof xany</b>: (#parse_filter_info_object)
 *         поле <b>filter</b> разбирается по правилам строки #parse_filter_info_string
 *         дополнительно проверяется поле <b>params</b> на предмет разбора по правилам #filterParam.parse_params
 *         Если filter сожержит несколько фильтров - будет ошибка
 *  если <b>array === typeof xany</b>:
 *        каждый элемент, являющийся строкой или объектом разбирается по приведенным выше правилам
 *        каждый элемент, являющийся массивом разбирается рекурсивно.     
 *  Если <b>(xany instanceof Element) && (xany.tagName()==='property')</b>: 
 *     Если есть атрибут filter[s] - разбирается по правилам строки (/^>name</i удаляется в любом случае).   
 *     Все дочерние атрибуты filter разбираются по правилам #filterInfo.parse_xml_filter_element
 *  Если <b>(xany instanceof Element) && (xany.tagName()==='filter')</b>: 
 *     разбираются по правилам #filterInfo.parse_xml_filter_element 
 * @param {string|object|array} xany
 * @returns {filterInfo[]} массив структур filterInfo
 */
filterInfo.parse_filter_info = function (xany) {
    var result = [];
    if (xany && U.is_object(xany) && window.Element && (xany instanceof Element)) {
        if ('filter' === xany.tagName.toLowerCase()) {
            result = result.concat(filterInfo.parse_xml_filter_element(xany));
        } else if ('property' === xany.tagName.toLowerCase()) {
            result = result.concat(filterInfo.parse_xml_property_element(xany));
        }
    } else if (is_string(xany)) {
        result = result.concat(filterInfo.parse_filter_info_string(xany));
    } else if (U.is_object(xany)) {
        result = result.concat(filterInfo.parse_filter_info_object(xany));
    } else if (U.is_array(xany)) {
        result = result.concat(filterInfo.parse_filter_info_array(xany));
    }

    return result;
};


export {filterInfo};

