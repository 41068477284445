import {abstractDefaultFilter} from './../abstractDefaultFilter';
import U from './../../lib-utils';
function F() {
    return (F.is(this) ? this.init : F.F).apply(this, Array.prototype.slice.call(arguments));
}

var P = F.leu_extend(abstractDefaultFilter).prototype;

// eslint-disable-next-line
P.get_default = function (current_value, params, field_name) {
    if (params.has_param('default')) {
        var v = U.intOr(params.get_param('default').value, null);
        if (null !== v) {
            return v;
        }
    }
    return 0;
};

export default F;


