import {abstractDefaultFilter} from './../abstractDefaultFilter';

function F() {
    return (F.is(this) ? this.init : F.F).apply(this,Array.prototype.slice.call(arguments));
}

var P = F.leu_extend(abstractDefaultFilter).prototype;

// eslint-disable-next-line
P.get_default = function (current_value, params, field_name) {
    return null;
};

export default F;


