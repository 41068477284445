
//<editor-fold defaultstate="collapsed" desc="IePolyfills">
if (!DocumentFragment.prototype.getElementById) {
    Object.defineProperty(DocumentFragment.prototype, 'getElementById', {
        enumerable: false,
        configurable: false,
        writable: false,
        value: function (o) {
            function deepscan(node, id, result) {
                if (!result.found) {
                    if (node.childNodes && node.childNodes.length) {
                        for (var i = 0; i < node.childNodes.length; i++) {
                            var cnode = node.childNodes[i];
                            if (cnode.id && cnode.id == id) {
                                result.found = cnode;
                            } else {
                                deepscan(cnode, id, result);
                            }
                        }
                    }
                }
            }
            var result = {found: null};
            deepscan(this, o, result);
            return result.found;
        }
    });
}
if (!(Element.prototype.remove)) {
    Object.defineProperty(Element.prototype, 'remove', {
        enumerable: false,
        configurable: false,
        writable: false,
        value: function () {
            if (this.parentNode) {
                this.parentNode.removeChild(this);
            }
        }
    });
}

// Источник: https://gist.github.com/k-gun/c2ea7c49edf7b757fe9561ba37cb19ca
(function () {
    // helpers
    var regExp = function (name) {
        return new RegExp('(^| )' + name + '( |$)');
    };
    var forEach = function (list, fn, scope) {
        for (var i = 0; i < list.length; i++) {
            fn.call(scope, list[i]);
        }
    };

    // class list object with basic methods
    function ClassList(element) {
        this.element = element;
    }

    ClassList.prototype = {
        add: function () {
            forEach(arguments, function (name) {
                if (!this.contains(name)) {
                    this.element.className += ' ' + name;
                }
            }, this);
        },
        remove: function () {
            forEach(arguments, function (name) {
                this.element.className =
                        this.element.className.replace(regExp(name), '');
            }, this);
        },
        toggle: function (name) {
            return this.contains(name)
                    ? (this.remove(name), false) : (this.add(name), true);
        },
        contains: function (name) {
            return regExp(name).test(this.element.className);
        },
        // bonus..
        replace: function (oldName, newName) {
            this.remove(oldName), this.add(newName);
        }
    };

    // IE8/9, Safari
    if (!('classList' in Element.prototype)) {
        Object.defineProperty(Element.prototype, 'classList', {
            get: function () {
                return new ClassList(this);
            }
        });
    }

    // replace() support for others
    if (window.DOMTokenList && DOMTokenList.prototype.replace == null) {
        DOMTokenList.prototype.replace = ClassList.prototype.replace;
    }
})();

//</editor-fold>

var C = 0, FN = 0, OB = 0;
//<editor-fold defaultstate="collapsed" desc="is-a-type">
function isCallable(x) {
    return x && typeof (x) === 'function' ? true : false;
}
function isObject(x) {
    return x && typeof (x) === 'object' ? true : false;
}
function safeObject(x) {
    return isObject(x) ? x : {};
}
function isArray(x) {
    return isObject(x) && (x instanceof Array) ? true : false;
}
function safeArray(x) {
    return isArray(x) ? x : [];
}
function NEArray(x, def) {
    return isArray(x) && x.length ? x : def;
}
function isError(x) {
    return isObject(x) && (x instanceof Error) ? true : false;
}

function isDOM(x) {
    return (isObject(x) && (x instanceof HTMLElement));
}

//</editor-fold>

function functionUID() {
    return ['F', (++FN)].join('');
}
function objectUID() {
    return ['O', (++OB)].join('');
}
function get_UUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

//<editor-fold defaultstate="collapsed" desc="extend functions">
function fixup_constructor(x) {
    if (isCallable(x)) {
        x.is = function (xx) {
            return isObject(xx) && (xx instanceof x) ? true : false;
        };
        x.F = function (a, b, c, d, e, f, h, i, j, k, l, m, n, o, p, q, r, s, t, u, xx, y, w, v, z) {
            return new x(a, b, c, d, e, f, h, i, j, k, l, m, n, o, p, q, r, s, t, u, xx, y, w, v, z);
        };
    }
    return x;
}
function bindTo(obj, func) {
    if (!isCallable(func)) {
        throw new Error("-lib-eve-utils-core: bind of non callable");
    }
    if (!isObject(obj)) {
        throw new Error("-lib-eve-utils-core: bind to non object");
    }
    if (!isCallable(func['-lib-eve-utils-function-get-id'])) {
        throw new Error("-lib-eve-utils-core: cant bind to first generation function");
    }
    isObject(obj['-lib-eve-utils-function-binds']) ? false : obj['-lib-eve-utils-function-binds'] = {};
    var bind_list = obj['-lib-eve-utils-function-binds'];
    var function_id = func['-lib-eve-utils-function-get-id']();
    if (!(function_id in bind_list)) {
        bind_list[function_id] = function () {
            return func.apply(obj, Array.prototype.slice.call(arguments));
        };
    }
    return bind_list[function_id];
}
/**
 * бинд, первый параметр вызова -  исходный контекст
 * @param {type} obj
 * @param {type} func
 * @returns {unresolved}
 */
function bindToWP(obj, func) {
    if (!isCallable(func)) {
        throw new Error("-lib-eve-utils-core: bind of non callable");
    }
    if (!isObject(obj)) {
        throw new Error("-lib-eve-utils-core: bind to non object");
    }
    if (!isCallable(func['-lib-eve-utils-function-get-id'])) {
        throw new Error("-lib-eve-utils-core: cant bind to first generation function");
    }
    var function_id = func['-lib-eve-utils-function-get-id']();
    isObject(obj['-lib-eve-utils-function-binds-wp']) ? false : obj['-lib-eve-utils-function-binds-wp'] = {};
    var bind_list = obj['-lib-eve-utils-function-binds-wp'];
    if (!(function_id in bind_list)) {
        bind_list[function_id] = function () {
            return func.apply(obj, [this].concat(Array.prototype.slice.call(arguments)));
        };
    }
    return bind_list[function_id];
}
function lib_eve_utils_object_create(fn) {
    if (!isCallable(fn)) {
        throw new Error("-lib-eve-utils-core: object create requires a function");
    }
    function F() {

    }
    F.prototype = fn.prototype;
    var ro = new F();
    //ro.constructor = COP;
    return ro;
}
//</editor-fold>

//<editor-fold defaultstate="collapsed" desc="Property defines">
Object.defineProperty(Function.prototype, '-lib-eve-utils-function-id', {// идентификатор функции второго поколения
    enumerable: false,
    configurable: false,
    writable: true,
    value: null
});
Object.defineProperty(Object.prototype, '-lib-eve-utils-object-id', {// идентификатор объекта
    enumerable: false,
    configurable: false,
    writable: true,
    value: null
});
Object.defineProperty(Function.prototype, '-lib-eve-utils-function-binds', {// простые бинды к объекту (список)
    enumerable: false,
    configurable: false,
    writable: true,
    value: null
});
Object.defineProperty(Object.prototype, '-lib-eve-utils-function-binds-wp', {// контекстные бинды к объекту (список)
    enumerable: false,
    configurable: false,
    writable: true,
    value: null
});
Object.defineProperty(Function.prototype, '-lib-eve-utils-function-get-id', {// генератор идентификатора для функции второго поколения
    enumerable: false,
    configurable: false,
    writable: false,
    value: function () {
        this['-lib-eve-utils-function-id'] ? false : this['-lib-eve-utils-function-id'] = functionUID();
        return this['-lib-eve-utils-function-id'];
    }
});
Object.defineProperty(Object.prototype, '-lib-eve-utils-object-get-id', {// генератор идентификатора для объекта
    enumerable: false,
    configurable: false,
    writable: false,
    value: function () {
        this['-lib-eve-utils-object-id'] ? false : this['-lib-eve-utils-object-id'] = objectUID();
        return this['-lib-eve-utils-object-id'];
    }
});

if (!isCallable(Function.prototype.bind_to)) {
    Object.defineProperty(Function.prototype, 'bind_to', {
        enumerable: false,
        configurable: false,
        writable: false,
        value: function (o) {
            return bindTo(o, this);
        }
    });
    Object.defineProperty(Function.prototype, 'bindTo', {
        enumerable: false,
        configurable: false,
        writable: false,
        value: function (o) {
            return bindTo(o, this);
        }
    });
}
/**
 * бинд с передачей <b>исходного</b> контекста в параметре
 */
if (!isCallable(Function.prototype.bindToWP)) {
    Object.defineProperty(Function.prototype, 'bindToWP', {
        enumerable: false,
        configurable: false,
        writeable: false,
        value: function (o) {
            return bindToWP(o, this);
        }
    });
    Object.defineProperty(Function.prototype, 'bind_to_wp', {
        enumerable: false,
        configurable: false,
        writeable: false,
        value: function (o) {
            return bindToWP(o, this);
        }
    });
}
if (!isCallable(Function.prototype.leu_inherit)) {// наследование
    Object.defineProperty(Function.prototype, 'leu_inherit', {
        enumerable: false,
        configurable: false,
        writable: false,
        value: function (super_func) {
            this.prototype = lib_eve_utils_object_create(super_func);
            this.prototype.constructor = this;
            return this;
        }
    });
}
if (!isCallable(Function.prototype.leu_extend)) {// наследование + call-фабрика
    Object.defineProperty(Function.prototype, 'leu_extend', {
        enumerable: false,
        configurable: false,
        writable: false,
        value: function (super_func) {
            this.prototype = lib_eve_utils_object_create(super_func);
            this.prototype.constructor = this;
            return fixup_constructor(this);
        }
    });
}
//</editor-fold>
//<editor-fold defaultstate="collapsed" desc="string utils">
function UCFirst(x) {
    if (!(typeof (x) === 'string')) {
        try {
            x = x.toString();
        } catch (e) {
            return x;
        }
    }
    var xa = x.split('');
    return [xa.slice(0, 1).join('').toUpperCase(), xa.slice(1).join('')].join('');
}
function leu_trim(x) {
    if (typeof (x) !== 'string') {
        try {
            return x.toString().trim();
        } catch (e) {
            return '';
        }
    }
    return x.trim();
}

function padLeft(w, l, f) {
    try {
        var s = w.toString().split('');
        f = NEString(f, ' ');
        while (s.length < l) {
            s = [f].concat(s);
        }
        return s.join('');
    } catch (e) {
        // do nothing
    }
    return w;
}

function padRight(w, l, f) {
    try {
        var s = w.toString().split('');
        f = NEString(f, ' ');
        while (s.length < l) {
            s.push(f);
        }
        return s.join('');
    } catch (e) {
        // do nothing
    }
    return w;
}

function isString(x) {
    return !!(typeof (x) === 'string');
}

function NEString(x, def) {
    var test = null;
    if (typeof (x) !== 'string') {
        try {
            test = isObject(x) || isCallable(x) ? def : x.toString();
        } catch (e) {
            return def;
        }
    } else {
        test = x;
    }
    test = leu_trim(test);
    return test && test.length ? test : def;
}


//</editor-fold>
//<editor-fold defaultstate="collapsed" desc="casts">
function anyBool(x, def) {
    if (x === 1 || x === '1' || x === true || x === 'true' || x === 'on') {
        return true;
    }
    if (x === 0 || x === '0' || x === false || x === 'false' || x === 'off') {
        return false;
    }
    try {
        if (x.toLowerCase() === 'true' || x.toLowerCase() === 'on') {
            return true;
        }
        if (x.toLowerCase() === 'false' || x.toLowerCase() === 'off') {
            return false;
        }
    } catch (e) {
        // do nothing
    }
    return def;
}
function isInt(x) {
    var r = parseInt(x);
    return typeof (r) === 'number' && !isNaN(r) ? true : false;
}
function IntOr(test, def) {
    var t = parseInt(test);
    return typeof (t) === 'number' && !isNaN(t) ? t : def;
}
function IntMoreOr(test, more, def) {
    more = IntOr(more, 0);
    var result = IntOr(test, def);
    return isInt(result) && result > more ? result : def;
}
//<editor-fold defaultstate="collapsed" desc="float casts">
function prepareFloat(x) {
    try {
        return x.toString().replace(/\s/g, '').replace(/,/g, '.');
    } catch (e) {
        // do nothing
    }
    return x;
}
function FloatMoreOr(x, more, def) {
    x = parseFloat(prepareFloat(x));
    return (typeof (x) === 'number' && !isNaN(x) && x > more) ? x : def;
}

function FloatMoreEqOr(x, more, def) {
    x = parseFloat(prepareFloat(x));
    return (typeof (x) === 'number' && !isNaN(x) && x >= more) ? x : def;
}

function FloatOr(x, def) {
    var test = parseFloat(prepareFloat(x));
    return typeof (test) === 'number' && !isNaN(test) ? test : def;
}


//</editor-fold>

//</editor-fold>
//<editor-fold defaultstate="collapsed" desc="coalesce_* methods">
function coalesceString() {
    var args = Array.prototype.slice.call(arguments);
    for (var i = 0; i < args.length; i++) {
        if (NEString(args[i], null)) {
            return args[i];
        }
    }
    return void(0);
}
function coalesceObject() {
    var args = Array.prototype.slice.call(arguments);
    for (var i = 0; i < args.length; i++) {
        if (isObject(args[i])) {
            return args[i];
        }
    }
    return void(0);
}
function coalesceCallable() {
    var args = Array.prototype.slice.call(arguments);
    for (var i = 0; i < args.length; i++) {
        if (isCallable(args[i])) {
            return args[i];
        }
    }
    return void(0);
}

//</editor-fold>

var isMobile = {
    Android: function () {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function () {
        return navigator.userAgent.match(/IEMobile/i);
    },
    any: function () {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
};
function _isMobile() {
    return isMobile.any() ? true : false;
}

function scan_dom(node, search_for, result) {
    if (!isObject(result)) {
        result = {};
    }
    for (var i = 0; i < node.children.length; i++) {
        var c = node.children.item(i);
        if (c.hasAttribute(search_for)) {
            var s = NEString(c.getAttribute(search_for), null);
            if (s) {
                result[s] = c;
            }
        }
        if (c.children.length) {
            scan_dom(c, search_for, result);
        }
    }
    return result;
}


function adler32(data) {
    var MOD_ADLER = 65521;
    var a = 1, b = 0;
    var index;
    for (index = 0; index < data.length; ++index) {
        a = (a + data.charCodeAt(index)) % MOD_ADLER;
        b = (b + a) % MOD_ADLER;
    }
    var adler = a | (b << 16);
    return ["adler32:", adler].join('');
}

function fillProto(src, proto, target) {
    src = safeObject(src);
    proto = safeObject(proto);
    target = safeObject(target);
    for (var k in src) {
        if (Object.prototype.hasOwnProperty.call(src, k) && !isCallable(src[k])) {
            if (Object.prototype.hasOwnProperty.call(proto, k) && target[k] === null) {
                target[k] = src[k];
            }
        }
    }
    return target;
}

function is_defined(x) {
    return !!(typeof (x) !== 'undefined');
}

function deep_clone(src, target) {
    for (var k in src) {
        if (Object.prototype.hasOwnProperty.call(src, k)) {
            if (isObject(src[k])) {
                try {
                    deep_clone(src[k], target[k]);
                } catch (e) {
                    //do nothing
                }
            } else if (!isCallable(src[k])) {
                try {
                    target[k] = src[k];
                } catch (e) {
                    // do nothing
                }
            }
        }
    }
}

function get_body() {
    var bds = document.getElementsByTagName('body');
    if (bds.length) {
        return bds[0];
    }
    return null;
}


var utils = {
    //<editor-fold defaultstate="collapsed" desc="sequence methods">
    get_unique_id: function () {
        return ['uid_', (++C)].join('');
    },
    repeat_unique_id: function () {
        return ['uid_', C].join('');
    },
    get_number_unique_id: function () {
        return (++C);
    },
    repeat_number_unique_id: function () {
        return C + 0;
    },
    get_UUID: get_UUID,
    get_uuid: get_UUID,
    //</editor-fold>
    //<editor-fold defaultstate="collapsed" desc="is-a-type functions">
    isCallable: isCallable,
    is_callable: isCallable,
    isObject: isObject,
    is_object: isObject,
    isArray: isArray,
    is_array: isArray,
    safeArray: safeArray,
    safe_array: safeArray,
    NEArray: NEArray,
    safeObject: safeObject,
    safe_object: safeObject,
    isError: isError,
    is_error: isError,
    isDOM: isDOM,
    is_dom: isDOM,
    //</editor-fold>    
    //<editor-fold defaultstate="collapsed" desc="string utils">
    trim: leu_trim,
    UCFirst: UCFirst,
    uc_first: UCFirst,
    padLeft: padLeft,
    pad_left: padLeft,
    padRight: padRight,
    pad_right: padRight,
    NEString: NEString,
    ne_string: NEString,
    //</editor-fold>    
    //<editor-fold defaultstate="collapsed" desc="coalesce_*">
    coalesceString: coalesceString,
    coalesce_string: coalesceString,
    selectString: coalesceString,
    select_string: coalesceString,
    coalesceObject: coalesceObject,
    coalesce_object: coalesceObject,
    selectObject: coalesceObject,
    select_object: coalesceObject,
    coalesceCallable: coalesceCallable,
    coalesce_callable: coalesceCallable,
    selectCallable: coalesceCallable,
    select_callable: coalesceCallable,
    //</editor-fold>
    //<editor-fold defaultstate="collapsed" desc="cast">
    anyBool: anyBool,
    any_bool: anyBool,
    isInt: isInt,
    is_int: isInt,
    IntOr: IntOr,
    intOr: IntOr,
    int_or: IntOr,
    IntMoreOr: IntMoreOr,
    intMoreOr: IntMoreOr,
    int_more_or: IntMoreOr,
    prepareFloat: prepareFloat,
    prepare_float: prepareFloat,
    FloatOr: FloatOr,
    floatOr: FloatOr,
    float_or: FloatOr,
    FloatMoreOr: FloatMoreOr,
    floatMoreOr: FloatMoreOr,
    float_more_or: FloatMoreOr,
    FloatMoreEqOr: FloatMoreEqOr,
    floatMoreEqOr: FloatMoreEqOr,
    float_more_eq_or: FloatMoreEqOr,
    //</editor-fold>
    isMobile: _isMobile,
    is_mobile: _isMobile,
    scanDom: scan_dom,
    scan_dom: scan_dom,
    fixup_constructor: fixup_constructor,
    adler32: adler32,
    adler: adler32,
    is_string: isString,
    isString: isString,
    fill_proto: fillProto,
    fillProto: fillProto,
    is_defined: is_defined,
    deep_clone: deep_clone,
    get_body: get_body,
    getBody: get_body
};
export default utils;

