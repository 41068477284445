import { createRouter, createWebHistory } from 'vue-router';
import {central_data} from '@/lib/vendor/eve/central-data';

const routes = [
    //<editor-fold defaultstate="collapsed" desc="mod-login">
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "mod-login" */ '@/components/login')
    },
    //</editor-fold>
    //<editor-fold defaultstate="collapsed" desc="mod-user">
    {
        path: '/user-list/:page',
        name: 'userList',
        component: () => import(/* webpackChunkName: "mod-user" */ '@/components/user-list'),
        props: true


    },
    {
        path: '/user-list',
        component: () => import(/* webpackChunkName: "mod-user" */ '@/components/user-list'),
        props: true
    },
    {
        path: '/user/add',
        name: 'user-edit',
        props: true,
        component: () => import(/* webpackChunkName: "mod-user" */ '@/components/userEdit')
    },
    {
        path: '/user/:id',
        name: 'user-edit',
        props: true,
        component: () => import(/* webpackChunkName: "mod-user" */ '@/components/userEdit')
    },
    //</editor-fold>
    //<editor-fold defaultstate="collapsed" desc="mod-vocabulary">
    {
        path: '/voc-list',
        component: () => import('@/components/voc-list'),
        props: true
    },
    {
        path: '/voc-list/:page',
        component: () => import('@/components/voc-list'),
        props: true
    },
    //</editor-fold>
    //<editor-fold defaultstate="collapsed" desc="mod-tags">
    {
        path: '/tag-list',
        component: () => import(/* webpackChunkName: "mod-tags" */ '@/components/tag-list'),
        props: true
    },
    {
        path: '/tag-list/:page',
        component: () => import(/* webpackChunkName: "mod-tags" */ '@/components/tag-list'),
        props: true
    },
    {
        path: '/tag/add',
        props: true,
        component: () => import(/* webpackChunkName: "mod-tags" */ '@/components/tag-edit')
    },
    {
        path: '/tag/:tagid',
        props: true,
        component: () => import(/* webpackChunkName: "mod-tags" */ '@/components/tag-edit')
    },
    {
        path: '/tag-value-list/:tagid/:page',
        props: true,
        component: () => import(/* webpackChunkName: "mod-tags" */ '@/components/tag-value-list')

    },
    {
        path: '/tag-value-list/:tagid',
        props: true,
        component: () => import(/* webpackChunkName: "mod-tags" */ '@/components/tag-value-list')
    },
    {
        path: '/tag-value/:tagid/:valueid',
        props: true,
        component: () => import(/* webpackChunkName: "mod-tags" */ '@/components/tag-value-edit')
    },
    {
        path: '/tag-value/:tagid/add',
        props: true,
        component: () => import(/* webpackChunkName: "mod-tags" */ '@/components/tag-value-edit')
    },
    //</editor-fold>
    //<editor-fold defaultstate="collapsed" desc="mod-media">
    //film-list не включаем в чанк, поскольку он нужен для для home - 
    // чтобы грузился побыстрее
    {
        path: '/film-list',
        props: true,
        component: () => {
            if (central_data().jwtMonitor.isAdmin()) {
                return import('@/components/film-list');
            }
            return import('@/components/film-list-cli');
        }
    },

    {
        path: '/film-list/:page',
        props: true,
        component: () => {
            if (central_data().jwtMonitor.isAdmin()) {
                return import('@/components/film-list');
            }
            return import(/* webpackChunkName: "mod-cli" */ '@/components/film-list-cli');
        }
    },

    {
        path: '/film/add',
        props: false,
        component: () => {
            if (central_data().jwtMonitor.isAdmin()) {
                return import(/* webpackChunkName: "mod-media" */ '@/components/film-edit');
            }
            return import(/* webpackChunkName: "mod-indexer" */ '@/components/film-edit-cli');

        },
        name: 'new-film'
    },
    {
        path: '/film/:filmid',
        props: true,
        component: () => import(/* webpackChunkName: "mod-media" */ '@/components/film-edit')
    },
    //</editor-fold>   
    //<editor-fold defaultstate="collapsed" desc="mod-collections">
    {
        path: '/collection-list',
        props: true,
        component: () => {

            return import(/* webpackChunkName: "mod-collections" */ '@/components/collection-list');
        }
    },

    {
        path: '/collection-list/:page',
        props: true,
        component: () => {
            return import(/* webpackChunkName: "mod-collections" */ '@/components/film-list-cli');
        }
    },
    {
        path: '/collection/add',
        props: false,
        component: () => {
            return import(/* webpackChunkName: "mod-collections" */ '@/components/collection-edit');
        },
        name: 'new-film'
    },
    {
        path: '/collection/:collectionid',
        props: true,
        component: () => import(/* webpackChunkName: "mod-collections" */ '@/components/collection-edit')
    },
    //</editor-fold>     
    //<editor-fold defaultstate="collapsed" desc="mod-cli">
    {
        path: '/film-list-cli',
        props: true,
        component: () => import(/* webpackChunkName: "mod-cli" */ '@/components/film-list-cli')
    },
    {
        path: '/film-list-cli/:page',
        props: true,
        component: () => import(/* webpackChunkName: "mod-cli" */ '@/components/film-list-cli')
    },

    //</editor-fold>          
    //<editor-fold defaultstate="collapsed" desc="mod-news">
    {
        path: '/news-list',
        props: true,
        component: () => import(/* webpackChunkName: "mod-news" */ '@/components/news-list')

    },
    {
        path: '/news-list/:page',
        props: true,
        component: () => import(/* webpackChunkName: "mod-news" */ '@/components/news-list')
    },

    {
        path: '/News/add',
        props: false,
        component: () => import(/* webpackChunkName: "mod-news" */ '@/components/news-edit'),
        name: 'new-news'
    },
    {
        path: '/News/:newsid',
        props: true,
        component: () => import(/* webpackChunkName: "mod-news" */ '@/components/news-edit'),
        name: 'edit-news'
    },
    //</editor-fold>
    //<editor-fold defaultstate="collapsed" desc="mod-push">
    {

        path: '/push-list',
        props: true,
        component: () => import(/* webpackChunkName: "mod-push" */ '@/components/push-list')

    },
    {
        path: '/push-list/:page',
        props: true,
        component: () => import(/* webpackChunkName: "mod-push" */ '@/components/push-list')
    },
    {
        path: '/push/add',
        props: true,
        component: () => import(/* webpackChunkName: "mod-push" */ '@/components/push-edit'),
        name: 'add-push'
    },
    {
        path: '/push/:pushid',
        props: true,
        component: () => import(/* webpackChunkName: "mod-push" */ '@/components/push-display'),
        name: 'display-push'
    },
    //</editor-fold>
    //<editor-fold defaultstate="collapsed" desc="mod-review">
    {
        path: '/review-list',
        props: true,
        component: () => import(/* webpackChunkName: "mod-review" */ '@/components/review-list')
    },
    {
        path: '/review-list/:page',
        props: true,
        component: () => import(/* webpackChunkName: "mod-review" */ '@/components/review-list')
    },
    //</editor-fold>
    //<editor-fold defaultstate="collapsed" desc="mod-indexer">
    {
        path: '/indexer-state/:uid',
        props: true,
        component: () => import(/* webpackChunkName: "mod-indexer" */ '@/components/indexer-state')
    },
    {
        path: '/film-cli/add',
        props: false,
        component: () => import(/* webpackChunkName: "mod-indexer" */ '@/components/film-edit-cli'),
        name: 'new-film-cli'
    },
    //</editor-fold>
    {
        path: '/',
        name: 'home',
        component: () => {
            if (central_data().jwtMonitor.isAdmin()) {
                return import('@/components/film-list');
            }
            return import(/* webpackChunkName: "mod-cli" */ '@/components/film-list-cli');
        },
        props: true
    }
];
var route_id = 0;
const named_routes = routes.map(function (x) {
    if (!x.name) {
        x.name = ["rt", route_id].join('');
        route_id++;
    }
    return x;
});

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: named_routes
});
router.beforeResolve(function (a, b, c) {
    console.log(arguments);
    c();
});


function reloadRoutes() {
    for (var iRoute = 0; iRoute < named_routes.length; iRoute++) {
        router.removeRoute(named_routes[iRoute].name);
    }
    for (var aRoute = 0; aRoute < named_routes.length; aRoute++) {
        router.addRoute(named_routes[aRoute]);
    }
    console.log('routes reloaded');
    //router.go();
    //console.log('force reload');
}

central_data().ready(function () {
    window.xxrouter = router;
    central_data().jwtMonitor.on({}, reloadRoutes);


});

export default router
