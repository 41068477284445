import {abstractFilter} from './../abstractFilter';
import U from './../../lib-utils';
function F() {
    return (F.is(this) ? this.init : F.F).apply(this, Array.prototype.slice.call(arguments));
}

var P = F.leu_extend(abstractFilter).prototype;


P.apply_filter = function (current_value, params, field_name) {
    if ((typeof current_value) === 'string') {
        var filler = ' ';
        var length = 2;
        if (params.has_param('filler')) {
            filler = U.NEString(params.get_param('filler').value, filler);
        }
        if (params.has_param('length')) {
            length = U.intMoreOr(params.get_param('length').value, 0, length);
        }
        if (filler.length && length) {
            if (params.has_param('left') && U.any_bool(params.get_param('left').value, false)) {
                return U.pad_left(current_value, length, filler);
            } else if (params.has_param('right') && U.any_bool(params.get_param('right').value, false)) {
                return U.pad_right(current_value, length, filler);
            }
        }
        return current_value;

    }
    return this.create_invalid_value(field_name, 'is not string');
};

export default F;


