import {abstractFilter} from './../abstractFilter';
function F() {
    return (F.is(this) ? this.init : F.F).apply(this, Array.prototype.slice.call(arguments));
}

var P = F.leu_extend(abstractFilter).prototype;

// eslint-disable-next-line
P.apply_filter = function (current_value, params, field_name) {    
    if ((typeof current_value) === 'string') {
        if (current_value.length > 0) {
            return current_value;
        } else {
            return this.create_invalid_value(field_name, 'is empty');
        }
    } else {
        return this.create_invalid_value(field_name, 'is not string');
    }
};

export default F;


