import {abstractFilter} from './../abstractFilter';
import U from './../../lib-utils';
function F() {
    return (F.is(this) ? this.init : F.F).apply(this, Array.prototype.slice.call(arguments));
}

var P = F.leu_extend(abstractFilter).prototype;


P.apply_filter = function (current_value, params, field_name) {
    var required = true;
    if (params.has_param('default')) {
        required = U.any_bool(params.get_param('default').value, true);
    }
    if (required === current_value) {
        return current_value;
    }
    return this.create_invalid_value(field_name, ['required', (required ? 'true' : 'false')].join(' '));
};

export default F;


